@font-face {
  font-family: "Rolex";
  src: url("RolexFont-Regular-WebS.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RolexLight";
  src: url("RolexFont-Light-WebS.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNow";
  src: url("HelveticaNow.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("HelveticaNeue.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$rolex: "Rolex";
$rolexLight: "RolexLight";
$helveticaNow: "HelveticaNow";
$helveticaNeue: "HelveticaNeue";
