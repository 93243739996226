@import "../../assets/fonts/fonts";

.maintenance {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/watch-ID-access.jpg");
  height: 100vh;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  .imagesWrapper > img {
    height: 15%;
    position: absolute;
    margin: 30px auto 0px auto;
    left: 0;
    right: 0;
  }
  .maintenanceContent {
    text-align: center;
    position: absolute;
    color: #fff;
    left: 0;
    right: 0;
    width: 90vw;
    margin: 26vh auto 0;
    .bigTitle {
      font-size: 18px;
      font-family: $rolex;
      letter-spacing: 0.05em;
      margin-bottom: 20px;
    }
    .smallTitle {
      margin: 0 auto;
      font-size: 16px;
      font-family: $helveticaNow;
      line-height: 1.2;
      display: block;
      padding-bottom: 0.5vh;
    }
  }
}
